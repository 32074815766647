import React, { SyntheticEvent, useState, useEffect } from "react"
import { Box } from "@mui/system"
import { iUserWithId } from "../../interfaces/user.interfaces"
import userService from "../../services/users/user.service"
import { useParams, useNavigate } from "react-router-dom"
import PersonalData from "src/components/moleculas/EmployeDetailsComponent/PersonalData"
import Bio from "src/components/moleculas/EmployeDetailsComponent/Bio"
import Skills from "src/components/moleculas/EmployeDetailsComponent/Skills"
import Loading from "../Loading"
import AboutMeEmployee from "src/components/moleculas/EmployeDetailsComponent/AboutMeEmployee"
import { NotificationManager } from "react-notifications"
import CertificationsView from "src/components/organisms/CertificationView"
import AssignmentsView from "src/components/organisms/AssignmentsView/AssignmentsView"
import withSidebar from "src/components/organisms/Sidebar"
import { CardTeamMember } from "src/stories/components"
import { hasPermissions } from "src/helpers/user/UserHelper"
import useEmployees from "./Hooks/use-employees"
import { useRecoilValue } from "recoil"
import authState from "src/recoil/auth"
import Button from "@mui/material/Button"
import "./Scss/Employees.scss"
import ProfessionalView from "src/components/moleculas/ProfessionalView"
import ROUTES from "src/types/Routes"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabIndex: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabIndex, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const EmployeDetails = () => {
  const { employees } = useEmployees()
  const currentUserLoged = useRecoilValue(authState.user)
  const userHasPermissions = hasPermissions([
    ...(currentUserLoged.companyRole?.permissions || []),
    ...(currentUserLoged.permissions || []),
  ])
  const params = useParams()
  const id = params.idEmployee

  const [currentUser, setCurrentUser] = useState<iUserWithId>()
  const [value, setValue] = useState(0);

  useEffect(() => {
    userService
      .getUser(id!)
      .then(setCurrentUser)
      .catch((e) => NotificationManager.error("No user found"))
  }, [id])

  if (!currentUser) {
    return <Loading />
  }

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className="class-employeeDetail">
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => window.location.href = ROUTES.EMPLOYEES}
        fullWidth
        className="back-button"
      >
        Back
      </Button>
      <PersonalData currentPageEmployees={currentUser} />
      <div
        className={
          userHasPermissions("user:edit")
            ? "class-child-admin class-child-profile"
            : " class-child-profile"
        }
      >
        {userHasPermissions("user:view") ? (
          <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab label="Professional" {...a11yProps(0)} />
              <Tab label="Skills" {...a11yProps(1)} />
              <Tab label="Certificate" {...a11yProps(2)} />
              <Tab label="Assignments" {...a11yProps(3)} />
              <Tab label="About Me" {...a11yProps(4)} />
            </Tabs>
            <TabPanel tabIndex={value} index={0}>
              <ProfessionalView user={currentUser} />
            </TabPanel>
            <TabPanel tabIndex={value} index={1}>
              <Skills currentPageEmployees={currentUser} />
            </TabPanel>
            <TabPanel tabIndex={value} index={2}>
              <CertificationsView certifications={currentUser.certifications} />
            </TabPanel>
            <TabPanel tabIndex={value} index={3}>
              <AssignmentsView assignments={currentUser?.assignments || []} id={currentUser.id}/>
            </TabPanel>
            <TabPanel tabIndex={value} index={4}>
              <Bio currentPageEmployees={currentUser} />
              <AboutMeEmployee currentUser={currentUser} />
            </TabPanel>
          </Box>
        ) : (
          <div>
            <Bio currentPageEmployees={currentUser} />
            <AboutMeEmployee currentUser={currentUser} />
            <ProfessionalView user={currentUser} />
            <Skills currentPageEmployees={currentUser} />
            <CertificationsView certifications={currentUser.certifications} />
          </div>
        )}
        {!userHasPermissions("user:manage") && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Box
              sx={{
                fontSize: 16,
                fontWeight: "medium",
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#828689",
              }}
            >
              Look another members
            </Box>
            {employees
              .filter(
                (data: any, key: number) =>
                  key <= 4 && data.email !== currentUser.email
              )
              .map((data: any) => (
                <CardTeamMember
                  userName={data.fullName}
                  urlImage={data.photoURL}
                  status={
                    data.isCurrentEmployee
                      ? data.inBench
                        ? "In branch"
                        : "In project"
                      : "Suspended"
                  }
                  SkillArray={[
                    {
                      backgroundColor: "#0083D2",
                      borderRadius: "all",
                      color: "#4fc4ff",
                      disabled: true,
                      iconBolean: true,
                      iconColor: "#F5F5F5",
                      iconType: "IconReact",
                      isIconOnly: true,
                      label: "Button name",
                      primary: true,
                      variant: "contained",
                    },
                    {
                      backgroundColor: "#E11F1F",
                      borderRadius: "all",
                      color: "#4fc4ff",
                      disabled: true,
                      iconBolean: true,
                      iconColor: "#F5F5F5",
                      iconType: "IconAngular",
                      isIconOnly: true,
                      label: "Button name",
                      primary: true,
                      variant: "contained",
                    },
                    {
                      backgroundColor: "#618C03",
                      borderRadius: "all",
                      color: "#fafff0",
                      disabled: true,
                      iconBolean: false,
                      iconColor: "#F5F5F5",
                      isIconOnly: false,
                      label: "+88",
                      primary: true,
                      variant: "contained",
                    },
                  ]}
                  cardType="result"
                  userDescription=""
                />
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default withSidebar(EmployeDetails, { showProfileBar: true })
