import { Box } from "@mui/system"
import { Typography } from "@mui/material"
import AssignmentsTable from "src/components/moleculas/AssignmentsTable"
import { AssignmentsInterface } from "src/interfaces/user.interfaces"
import "./AssignmentsView.scss"

interface Props {
  assignments: AssignmentsInterface[]
  id: string
}

const AssignmentsView = ({ assignments, id }: Props) => {

  return (
    <Box
      sx={{
        borderRadius: 2,
        p: 2,
        minWidth: 300,
        gap: 2,
        margin: "30px 0",
      }}
    >
      <Typography style={{ color: "#828689" }}>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: "medium",
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginBottom: 2,
          }}
        >
          Assignments
        </Box>
      </Typography>
      <Box sx={{ color: "text.secondary" }} className="class-AssignmentsView">
        <AssignmentsTable assignments={assignments} id={id}/>
      </Box>
    </Box>
  )
}

export default AssignmentsView
