import React, { useMemo, useState } from 'react'
import styled from "@emotion/styled"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { AssignmentsInterface } from "src/interfaces/user.interfaces"
import useProjects from 'src/pages/Projects/ViewProjects/use-projects'

interface Props {
  assignments: AssignmentsInterface[]
  id: string
}

const AssignmentsViewTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const AssignmentsTable = ({
  assignments,
  id
}: Props) => {
    const { projectsFiltered } = useProjects();
    const getAssignmentData = useMemo(() => {
        const getData: any[] = [];
        projectsFiltered.forEach(project => {
          project.employees.forEach(user => {
            if (user.id === id) {
              getData.push({
                id: project.id,
                projectName: project.name,
                clientName: project.clientName,
                role: user.role,
                seniority: user.seniority,
                allocation: user.allocationPercentage,
                startDate: user.dayStart,
                endDate: user.dayFinish
              });
            }
          });
        });
        return getData;
      }, [assignments.length === 0, projectsFiltered]);

  return (
    <TableContainer
      sx={{ overflowY: "auto" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <AssignmentsViewTableHead>
            <TableCell>Project Name</TableCell>
            <TableCell>Client Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Seniority</TableCell>
            <TableCell>Allocation</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell></TableCell>
          </AssignmentsViewTableHead>
        </TableHead>
        <TableBody>
          {assignments && assignments.length > 0 ? (
            assignments.map((row) => (
              <TableRow key={row?.id}>
                <TableCell>{row?.projectName}</TableCell>
                <TableCell>{row?.clientName}</TableCell>
                <TableCell>{row?.role}</TableCell>
                <TableCell>{row?.seniority}</TableCell>
                <TableCell>{row?.allocation}</TableCell>
                <TableCell>{row?.startDate}</TableCell>
                <TableCell>{row?.endDate}</TableCell>
              </TableRow>
            ))
          ) : getAssignmentData && getAssignmentData.length > 0 ? getAssignmentData.map(data => <TableRow key={data?.id}>
                <TableCell>{data.projectName ? data.projectName : "--"}</TableCell>
                <TableCell>{data.clientName ? data.clientName : "--"}</TableCell>
                <TableCell>{data.role.name ? data.role.name : "--"}</TableCell>
                <TableCell>{data.seniority ? data.seniority : "--"}</TableCell>
                <TableCell>{data.allocation ? `${data.allocation}%` : "--"}</TableCell>
                <TableCell>{data.startDate ? data.startDate : "--"}</TableCell>
                <TableCell>{data.endDate ? data.endDate : "--"}</TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={6}>No assignments added yet</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AssignmentsTable
