import React, { useState } from "react"
import styled from "@emotion/styled"
import { DeleteOutline } from "@mui/icons-material"
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material"
import { skillLevelMapper } from "src/helpers/mappers/skillLevel.mapper"
import { iSkillFixed } from "src/interfaces/skills.interfaces"
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon
} from "@heroicons/react/outline"

interface Props {
  skills: iSkillFixed[]
  onDelete?: (id: string) => void
  minHeight?: number
}

const TechLevelTableHead = styled(TableRow)(({ theme }) => ({
  "&": {
    boxShadow: "0px 3px 6px #1C212426",
    opacity: 1,
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  "& th": {
    borderBottom: "0",
  },
}))

const SkillsTable = ({ skills, onDelete, minHeight }: Props) => {
  const [sortedByLevel, setUpSortedByLevel] = useState<boolean | undefined>(undefined)
  const [sortedByYear, setUpSortedByYear] = useState<boolean | undefined>(undefined)
  const [currentSkills, setCurrentSkills] = useState<iSkillFixed[]>(skills)

  const sortBySkill = () => {
    let localSortedBySkill: boolean | undefined = undefined

    sortedByLevel === false ? localSortedBySkill = true : sortedByLevel === undefined ? localSortedBySkill = false : localSortedBySkill = undefined

    if (skills) {
      const newSkills = [...skills]
      const sortedSkill = localSortedBySkill === undefined ? newSkills : newSkills.sort((a, b) => {
        if (a.skillLevel < b.skillLevel) {
          return !localSortedBySkill ? -1 : 1
        }
        if (a.skillLevel > b.skillLevel) {
          return !localSortedBySkill ? 1 : -1
        }
        return 0
      })

      setCurrentSkills(sortedSkill)

      setUpSortedByLevel(
        sortedByLevel === false ? true :
        sortedByLevel === undefined ? false :
        sortedByLevel === true ? undefined : sortedByLevel
      )
    }

    setUpSortedByYear(undefined)
  }

  const sortByYear = () => {
    let localSortedBySkill: boolean | undefined = undefined

    sortedByYear === false ? localSortedBySkill = true : sortedByYear === undefined ? localSortedBySkill = false : localSortedBySkill = undefined

    if (skills) {
      const newSkills = [...skills]
      const sortedSkill = localSortedBySkill === undefined ? newSkills : newSkills.sort((a, b) => {
        const aYear = a.year || 0
        const bYear = b.year || 0

        if (aYear < bYear) {
          return !localSortedBySkill ? -1 : 1
        }
        if (aYear > bYear) {
          return !localSortedBySkill ? 1 : -1
        }
        return 0
      })

      setCurrentSkills(sortedSkill)

      setUpSortedByYear(
        sortedByYear === false ? true :
        sortedByYear === undefined ? false :
        sortedByYear === true ? undefined : sortedByYear
      )
    }

    setUpSortedByLevel(undefined)
  }

  return (
    <TableContainer
    >
      <Table aria-label="simple table">
        <TableHead>
          <TechLevelTableHead>
            <TableCell>Tech Name</TableCell>
            <TableCell
              onClick={sortBySkill}
              className={sortedByLevel !== undefined ? "class-sort-table" : ""}
            >
              Level
              {sortedByLevel === undefined ? null : !sortedByLevel ? (
                <ArrowCircleUpIcon style={{ width: "1em" }} />
              ) : (
                <ArrowCircleDownIcon style={{ width: "1em" }} />
              )}
            </TableCell>
            <TableCell
              onClick={sortByYear}
              className={sortedByYear !== undefined ? "class-sort-table" : ""}
            >
              Years of Exp
              {sortedByYear === undefined ? null : !sortedByYear ? (
                <ArrowCircleUpIcon style={{ width: "1em" }} />
              ) : (
                <ArrowCircleDownIcon style={{ width: "1em" }} />
              )}
            </TableCell>
            <TableCell></TableCell>
          </TechLevelTableHead>
        </TableHead>
        <TableBody>
          {skills.length > 0 ? (
            currentSkills.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.tech}</TableCell>
                <TableCell>{skillLevelMapper[row.skillLevel]}</TableCell>
                <TableCell>{row.year}</TableCell>
                {onDelete && (
                  <TableCell width={1}>
                    <IconButton
                      onClick={() => {
                        onDelete(row.id)
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}>No skills added yet</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SkillsTable
