import React, { useEffect, useState, SyntheticEvent } from "react"
import { NotificationManager } from "react-notifications"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"
import { useNavigate } from "react-router-dom"
import Bio from "src/components/moleculas/EmployeDetailsComponent/Bio"
import Skills from "src/components/moleculas/EmployeDetailsComponent/Skills"
import PersonalData from "src/components/moleculas/EmployeDetailsComponent/PersonalData"
import { useRecoilState } from "recoil"
import authState from "src/recoil/auth"
import CertificationsView from "src/components/organisms/CertificationView"
import withSidebar from "src/components/organisms/Sidebar"
import ROUTES from "src/types/Routes"
import AboutMeEmployee from "src/components/moleculas/EmployeDetailsComponent/AboutMeEmployee"
import { Typography } from "@mui/material"
import { CardTeamMember } from "src/stories/components"
import useEmployees from "./Employees/Hooks/use-employees"
import { useRecoilValue } from "recoil"
import UserHelper, { hasPermissions } from "src/helpers/user/UserHelper"
import ProfessionalView from "src/components/moleculas/ProfessionalView"
import AssignmentsView from "src/components/organisms/AssignmentsView/AssignmentsView"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabIndex: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabIndex, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Profile = () => {
  const { employees } = useEmployees()
  const navigate = useNavigate()
  const [
    user,
    // setUser
  ] = useRecoilState(authState.user)
  const [value, setValue] = useState(0);

  const currentUserLoged = useRecoilValue(authState.user)
  const userHasPermissions = hasPermissions([
    ...(currentUserLoged.companyRole?.permissions || []),
    ...(currentUserLoged.permissions || []),
  ])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("isFirstLogin")) {
        NotificationManager.success("Pleas fill your personal data", "Welcome")
        localStorage.removeItem("isFirstLogin")
      }
    }
  }, [user])

  if (!user) return null

  return (
    <div className="px-4 sm:px-6 lg:px-8 class-profile">
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          gap: 2,
          height: 60,
          paddingBottom: "20px",
        }}
      >
        <Button
          onClick={() => navigate(ROUTES.MY_PROFILE)}
          type="button"
          variant="contained"
          sx={{
            backgroundColor: "primary.blue",
            borderRadius: "30px",
            width: "200px",
            maxHeight: "42px",
          }}
        >
          Edit
        </Button>
      </Box>
      <Typography
        sx={{
          marin: "auto",
          fontSize: 24,
          fontWeight: "medium",
          gap: 2,
        }}
      >
        <PersonalData currentPageEmployees={user} />
        <div
          className={
            userHasPermissions("user:edit")
              ? "class-child-admin class-child-profile"
              : " class-child-profile"
          }
        >
          {userHasPermissions("user:view") ? (
            <Box
              sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
            >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab label="Professional" {...a11yProps(0)} />
              <Tab label="Skills" {...a11yProps(1)} />
              <Tab label="Certificate" {...a11yProps(2)} />
              <Tab label="Assignments" {...a11yProps(3)} />
              <Tab label="About Me" {...a11yProps(4)} />
            </Tabs>
            <TabPanel tabIndex={value} index={0}>
              <ProfessionalView user={user} />
            </TabPanel>
            <TabPanel tabIndex={value} index={1}>
              <Skills currentPageEmployees={user} />
            </TabPanel>
            <TabPanel tabIndex={value} index={2}>
              <CertificationsView certifications={user.certifications} />
            </TabPanel>
            <TabPanel tabIndex={value} index={3}>
              <AssignmentsView assignments={user?.assignments || []} id={user.id}/>
            </TabPanel>
            <TabPanel tabIndex={value} index={4}>
              <Bio currentPageEmployees={user} />
              <AboutMeEmployee currentUser={user} />
            </TabPanel>
          </Box>
            // <div>
            //   <ProfessionalView user={user} />
            //   <Skills currentPageEmployees={user} />
            //   <CertificationsView certifications={user.certifications} />
            //   <AssignmentsView assignments={user?.assignments || []} id={user.id}/>
            //   <Bio currentPageEmployees={user} />
            //   <AboutMeEmployee currentUser={user} />
            // </div>
          ) : (
            <div>
              <Bio currentPageEmployees={user} />
              <AboutMeEmployee currentUser={user} />
              <ProfessionalView user={user} />
              <Skills currentPageEmployees={user} />
              <CertificationsView certifications={user.certifications} />
            </div>
          )}
          {!userHasPermissions("user:manage") && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Box
                sx={{
                  fontSize: 16,
                  fontWeight: "medium",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  color: "#828689",
                }}
              >
                Look another members
              </Box>
              {employees
                .filter((data, key) => key <= 4 && data.email !== user.email)
                .map((data) => (
                  <CardTeamMember
                    userName={data.fullName}
                    urlImage={data.photoURL}
                    status={
                      data.isCurrentEmployee
                        ? data.inBench
                          ? "In branch"
                          : "In project"
                        : "Inactive"
                    }
                    SkillArray={[
                      {
                        backgroundColor: "#0083D2",
                        borderRadius: "all",
                        color: "#4fc4ff",
                        disabled: true,
                        iconBolean: true,
                        iconColor: "#F5F5F5",
                        iconType: "IconReact",
                        isIconOnly: true,
                        label: "Button name",
                        primary: true,
                        variant: "contained",
                      },
                      {
                        backgroundColor: "#E11F1F",
                        borderRadius: "all",
                        color: "#4fc4ff",
                        disabled: true,
                        iconBolean: true,
                        iconColor: "#F5F5F5",
                        iconType: "IconAngular",
                        isIconOnly: true,
                        label: "Button name",
                        primary: true,
                        variant: "contained",
                      },
                      {
                        backgroundColor: "#618C03",
                        borderRadius: "all",
                        color: "#fafff0",
                        disabled: true,
                        iconBolean: false,
                        iconColor: "#F5F5F5",
                        isIconOnly: false,
                        label: "+88",
                        primary: true,
                        variant: "contained",
                      },
                    ]}
                    cardType="result"
                    userDescription=""
                  />
                ))}
            </div>
          )}
        </div>
      </Typography>
    </div>
  )
}

export default withSidebar(Profile)
